import React from "react"
import {Container, Row, Button} from 'react-bootstrap'
import {Link, graphql} from 'gatsby'

import BlogCard from "../components/BlogCard/BlogCard"

import Layout from "../components/Layout/Layout"
import SEO from "../components/seo"

const NotFoundPage = (props) => {

  const postList = props.data.allContentfulBlogPost

  return (<Layout>
    <SEO title="404: Not found" />
    <div>
      <div className="page-header" style={{height: "220px"}}>
        <Container>
          <h1 style={{ padding: "40px" }}>404: Not Found</h1>
          <Button href="/" variant="outline-success" size="lg" className="btn-gridfabric-outline" >Go Home</Button>
        </Container>
      </div>
    </div>
    <Container style={{ padding: "40px" }}>
      <Row>
        <h2 style={{width: "100%"}}>Try our other relevant pages</h2>
        <p>
          For grid operators, utilities and aggregators, look at{" "}
          <Link to="/canvas">Canvas</Link>, our OpenADR VTN server
        </p>
        <p>
          For product manufacturers, look at <Link to="/plaid">Plaid</Link>, which
          helps companies get their devices compliant with OpenADR to connect to participate in shifting programs
        </p>
        <p>
          Try our <Link to="/blog">blog</Link>, check out some of our
          latest posts:
        </p>
      </Row>
      <Row>
        {postList.edges.map(({ node }, i) => (
          <BlogCard post={node} key={i} />
        ))}
      </Row>
    </Container>
  </Layout>
)}

export default NotFoundPage

export const shortBlogQuery = graphql`
  query ShortBlogQuery {
           allContentfulBlogPost(sort: { fields: publishDate, order: DESC }, limit: 3) {
             edges {
               node {
                 slug
                 heroImage {
                   fluid(maxWidth: 786) {
                     ...GatsbyContentfulFluid
                                      }
                 }
                 publishDate
                 title
                 tags
                 description {
                   description
                 }
               }
             }
           }
  }
`
